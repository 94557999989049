<template>
	<v-container fluid fill-height style="background: #90A4AE">
		<v-layout flex flex-column align-center justify-center>
			<v-card width="400" class="mx-auto mt-5" color="white">
				<v-card-title>
					<v-col class="text-center">
						<img height="100" alt="AJ Producciones y Eventos" src="@/assets/logo.png">
					</v-col>
				</v-card-title>
				<v-card-text>
					<v-form v-model="formValidity">
						<v-text-field label="Password" type="Password" prepend-icon="mdi-lock" :rules="passwordRules"
							v-model="password" required color="accent" />
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn @click.stop="login()" color="primary" class="white--text">
								INGRESAR
							</v-btn>

						</v-card-actions>
					</v-form>

				</v-card-text>
			</v-card>
		</v-layout>
	</v-container>
</template>
<script>
export default {
	name: 'LoginOperator',
	components: {},
	mounted() {
	},
	data() {
		return {
			formValidity: false,
			password: '',
			passwordRules: [
				value => !!value || 'Contraseña es requerida',
			],
		}
	},
	methods: {
		login() {
			if (this.password.trim().toLowerCase() == '3040' ) {

				this.$router.push(`/event/express/${this.$route.params.evento_id}`)

			} else {
				window.alert('CONTRASEÑA INCORRECTA')
			}
		}
	},
	computed: {
	}
}
</script>