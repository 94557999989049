<template>
  <div>
    <v-main class="pt-4 pb-10 mb-5" >
      <SlideEvents />
    </v-main>
			<v-container>
				<v-row justify="center">
					<v-col cols="12">

						<section-header
							header="El éxito de su evento es nuestro principal objetivo!"
							sub-header="HISTÓRICO DE EVENTOS"
						/>
					</v-col>
					<v-col cols="12" sm="10">
						<v-card :loading="loading"> 
							<v-card-title>
								<h2>Listado completo de Eventos</h2>
								<v-spacer></v-spacer>
									<v-text-field
										v-model="search"
										append-icon="mdi-magnify"
										label="Search"
										single-line
										hide-details
									></v-text-field>
							</v-card-title>

							<v-data-table
								:headers="headers"
								:items="eventos"
								:search="search"
								:footer-props="{
									'items-per-page-options': [15, 30, 50]
								}"
								:items-per-page="15"
								item.key='eventos.id'
							>



								<!-- Afiche -->
								<template v-slot:item.image="{ item }">
										<img :src="item.image" width="150"/>
								</template>

								<!-- Descripcion -->
								<template v-slot:item.titulo="{ item }">

										<div class="text-left">
											<span class="font-weight-bold" v-html="item.titulo"/><br/>
												{{ dateFormated(item.desde) }} al {{ dateFormated(item.hasta) }}<br/>
											<span v-html="item.lugar" />
										</div>

								</template>


							</v-data-table>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
  </div>
</template>

<script>
	import { db } from '@/main'
//	import EventService from '@/services/EventService.js'
	import eventosData from '@/data/eventos.json'
	import moment from "moment";
  export default {
    name: 'evento',
    head() {
      return {
          title: 'Eventos',
          description: 'Consultoria, producción y organización profesional de eventos científicos y corporativos'
      }
		},

		data () {
			return {
				loading: false,
				search: '',
				eventos: [],
				events: [],
				headers: [
					{text: '', value: 'image', align: 'left'},
					{text: '', value: 'titulo', align: 'left'},
				],
			}
		},
    components: {
      SlideEvents: () => import('@/components/SlideEvents'),
    },
    mounted(){
      this.getData()

		},
		methods: {
			async getData(){
				let me = this
				this.loading = true;
			
        try {
					
					db.collection("events")
					.get()
					.then((res) => {

						res.forEach((snap) => {
							me.eventos.push({
								'titulo': snap.data().title,
								'desde': snap.data().starting_date,
								'hasta': snap.data().ending_date,
								'image': snap.data().photo_url,
								'lugar': snap.data().venue
							})
						});

						me.eventos.sort(function(b, a) {
								var c = new Date(a.desde);
							var d = new Date(b.desde);
							return c-d;
						});
						me.eventos.push(...eventosData)

					});




				


        }
        catch (e) {
          console.log(e)
        }
        finally {
          me.loading = false;
        }
      },
			dateFormated(date){
        return moment( new Date(date) ).format('DD/MM/YYYY')
      },
		},
    computed: {

    }

  }
</script>

<style>

.v-data-table .v-data-table__mobile-table-row {
    display: flex !important;
}

.v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
    height: 250px !important;
}
</style>
